import React from 'react';
import '../App.css'

function Intro(props) {
  return (
    <div className="section" style={{backgroundColor: "#f9faff"}}>
      <div className="sectionImage">
      <img src={'/baileyPhotos/bailey_14.jpeg'} alt={"bailey"} style={{width:"50%"}}/> 
      </div>

      <div className="sectionInfo">
        <div id="text"> 
          <h1 style={{fontWeight:"bolder", marginTop:0}} >Jessica Sanborn</h1>
          <h4>👩🏼‍💻 Full Stack Developer</h4>
          <h4>🎓 CS @ CU Boulder</h4>
          <h4>🐶 Obsessed with my dog Bailey</h4>
        </div>
      </div>
    </div>
  );
}
  
function AboutMe(props) {
  return (
    <div className="section" style={{backgroundColor: "white"}}>
      <div className="sectionInfo">
        <div id="text"> 
          <h2 style={{fontWeight:"bolder", marginTop:0}} >Hello!</h2>
          <p>Hi my name is Jessica. 
            I am a senior at CU Boulder studying computer science. 
            I love my dog and am passionate about full stack development.
          </p>
        </div>
      </div>
    </div>
  );
}
  
function Home(props){
  return (
    <div>
      <Intro />
      <AboutMe />
    </div>
  );
}

export default Home;
