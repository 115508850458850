import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Link, Switch} from 'react-router-dom';
import {Helmet} from "react-helmet";
import './App.css';
import bailey from './assets/bailey.jpeg'
import Projects from './components/Projects'
import Dog from './components/Dog'
import About from './components/About'
import Home from './components/Home'
import NavBar from './components/NavBar'

function Footer(props){
  return (
    <div id="footer">
      <p> Created by Jessica Sanborn. Updated October 2020.</p>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <Router>
        <div>
        <NavBar /> 
        <Switch>
          <Route path="/projects">
            <Helmet>
                <title>Jessica's Website - My Projects</title>
            </Helmet>
            <Projects />
          </Route>
          <Route path="/dog">
          <Helmet>
          <title>Jessica's Website - My Dog</title>
            </Helmet>
            <Dog />
          </Route>
          <Route path="/about">
            <Helmet>
              <title>Jessica's Website - About Me</title>
            </Helmet>
            <About />
          </Route>
          <Route path="/">
            <Helmet>
              <title>Jessica's Website</title>
            </Helmet>
            <Home />
          </Route>
        </Switch>
        <Footer/>
        </div>
      </Router>
    </div>
  );
}

export default App;
