import React from 'react'
import '../App.css'



const Photos = (props) => {
  let images = [
    [
      { id: 6, title: 'Bailey', description: 'foo' },
      { id: 7, title: 'Bailey', description: 'foo' },
      { id: 8, title: 'Bailey', description: 'foo' },
      { id: 10, title: 'Bailey', description: 'foo' }
    ],
    [
      { id: 12, title: 'Bailey', description: 'foo' },
      { id: 13, title: 'Bailey', description: 'foo' },
      { id: 22, title: 'Bailey', description: 'foo' },
      { id: 16, title: 'Bailey', description: 'foo' },
      { id: 18, title: 'Bailey', description: 'foo' }
    ],
    [
      { id: 1, title: 'Bailey', description: 'foo' },
      { id: 2, title: 'Bailey', description: 'foo' },
      { id: 3, title: 'Bailey', description: 'foo' },
      { id: 4, title: 'Bailey', description: 'foo' },
      { id: 5, title: 'Bailey', description: 'foo' }
    ],
    [
      { id: 17, title: 'Bailey', description: 'foo' },
      { id: 19, title: 'Bailey', description: 'foo' },
      { id: 20, title: 'Bailey', description: 'foo' },
      { id: 21, title: 'Bailey', description: 'foo' },
      { id: 23, title: 'Bailey', description: 'foo' },
      { id: 9, title: 'Bailey', description: 'foo' }
    ]
  ]
  
  return (
    <div class="collageRow">
      <div class="collageCol">
        { images[0].map(({id, title, description}) => <img key={id} src={'/baileyPhotos/bailey_'+id+'.jpeg'} title={title+id} alt={description} />)}
      </div>
      <div class="collageCol">
        { images[1].map(({id, title, description}) => <img key={id} src={'/baileyPhotos/bailey_'+id+'.jpeg'} title={title+id} alt={description} />)}
      </div>
      <div class="collageCol">
        { images[2].map(({id, title, description}) => <img key={id} src={'/baileyPhotos/bailey_'+id+'.jpeg'} title={title+id} alt={description} />)}
      </div>
      <div class="collageCol">
        { images[3].map(({id, title, description}) => <img key={id} src={'/baileyPhotos/bailey_'+id+'.jpeg'} title={title+id} alt={description} />)}
      </div>
    </div>
  )
}

const Dog = (props) => {

  return (
    <div>
      <div className="section" style={{backgroundColor: '#f9faff'}}>
        <div className="sectionInfo">
          <div id="text"> 
            <h1 style={{fontWeight:"bolder", marginTop:0}} >Bailey</h1>
            <h4>🤪 Goofball</h4>
            <h4>🏊‍♂️ Loves to swim</h4>
            <h4>😊 Smiley</h4>
            <h4>🥏 Obsessed with frisbees</h4>
          </div>
        </div>
      </div>
      <div className="section" style={{backgroundColor: '#ffffff'}}>
        <Photos />
      </div>
    </div>
  );  
}

export default Dog;
