import React from 'react'
import '../App.css'
import FlyQRImage from '../assets/FlyQR_image.jpg'
import HinderImage from '../assets/Hinder_image.jpg'
import ReddrecImage from '../assets/Reddrec_image.png'

function Info ({name, date, description, contribution, tools, links}) {
    let bullets = description.map(item => 
        <li>{item}</li>
    )

    let myLinks = links.map(item => 
        <a style={{fontWeight:"lighter",color:"gray", marginTop:0, paddingRight: 10, fontSize: "calc(3px + 2vmin)"}} target="_blank" href= {item.url}>{item.name}</a>
    )
    return (
        <div className="sectionInfo">
            <div id="text"> 
                <h2 style={{fontWeight:"bolder", margin:0}} >{name}</h2>
                <h5 style={{fontWeight:"lighter",color:"gray", marginTop:0, marginBottom: 10}}>{date}</h5>
                <ul style={{padding: "0px", fontSize: "calc(5px + 2vmin)", marginBottom:0}}>
                    <li><b>Contribution:</b> {contribution}</li>
                    <li style={{paddingBottom:"15px"}}><b>Tools:</b> {tools}</li>
                    {bullets}
                </ul> 
                {myLinks}
            </div>
        </div>
    ); 
}

function Image ({image}) {
    return(
        <div className="sectionImage">
            <img src={image} style={{width:"75%"}} alt='project'/> 
        </div>  
    );
}

function Project({name, date, description, contribution, tools, links, color, image}) {

    return (
        <div className="section" style={{backgroundColor: color}}>
            {image && <Image image={image} /> }
            <Info name={name} date={date} description={description} contribution={contribution} tools={tools} links={links} />
        </div>
    );  
}

function Projects(props) {
    let projectsData = [
        {
            name: "Capstone Project - INSIGHTS Intervention", 
            date: "August 2020 - May 2021", 
            description: ["Built a React Native mobile app for INSIGHTS Intervention in a team of 5", "App contained the INSIGHTS blog and podcast for parents, and mobile games and coloring pages for kids"],
            contribution: "Design and front end development" ,
            tools: "React Native, Typescript",
            color: "#f9faff",
            links: [{name: "Mobile Site",url:"https://mobile.insightsintervention.com"}]
        },{
            name: "Recipe Manager", 
            date: "June 2020", 
            description: ["Web app to store your favorite recipes", "Allowed users to easily view and check off ingredients and steps as they go"],
            contribution: "First solo React project" ,
            tools: "React, Redux",
            color: "white",
            links: [{name: "Github",url:"https://github.com/jess2187/recipes-app"}]
        },{
            name: "Reddrec", 
            date: "May 2020", 
            description: ["Created a web app that recommends gaming subreddits to users based on their reddit comment history using Machine Learning", "Created recommender system for my Machine Learning final project"],
            contribution: "Built the frontend and contributed to recommender system" ,
            tools: "React, Redux, Python" ,
            image: ReddrecImage,
            color: "#f9faff",
            links: [{name: "Github",url:"https://github.com/reddrec/reddrec"}]
        },
        {
            name: "FlyQR", 
            date: "Feburary 2020", 
            description: ["Created a mobile app that add unique QR codes to campus posters", "Provided predictive analytics for best locations based off similar organizations"],
            contribution: "Designed an interactive prototype with Adobe XD and built the frontend" ,
            tools: "React Native, Expo, Adobe XD, Flask API" ,
            image: FlyQRImage,
            color: "white",
            links: [{name: "Github",url:"https://github.com/evanram/flyqr"}, {name: "Devpost",url:"https://devpost.com/software/flyqr"}]
        },
        {
            name: "Hinder",
            date: "December 2019",
            description: ["Designed a web app that matches you with other people to debate with", "Users complete survey of their opinions then are matched with someone with opposite ideas"],
            contribution: "Designed and built the web frontend",
            tools: "AngularJS, HTML, TypeScript, Java, Figma",
            image: HinderImage,
            color: "#f9faff",
            links: [{name: "Github",url:"https://github.com/TiffanyVPhan/Hinder"}, {name: "Devpost",url:"https://devpost.com/software/hinder-107m4k"}]
        },
        {
            name: "MyCUEverything",
            date: "February 2019",
            description: ["Designed a web app that allows CU Boulder students to find all school-related information in one place", "Scraped data from multiple websites then displayed it on our own web app"],
            contribution: "Designed and built the frontend, used Python to create graphs tracking student progress", 
            tools: "HTML, JavaScript, Python",
            color: "white",
            links: [{name: "Github",url:"https://github.com/TiffanyVPhan/MyCUEverything"}, {name: "Devpost",url:"https://devpost.com/software/mycueverything"}]
        },
        {
            name: "Student Calendar",
            date: "Fall 2018",
            description: ["Designed and built a calendar for students", "Users could add classes and schedule their study time based on assignment length and due date"],
            contribution: "Designed a prototype and used Bootstrap to build the frontend",
            tools: "HTML, Bootstrap, JavaScript, Node.js, Figma",
            color: "#f9faff",
            links: [{name: "Github",url:"https://github.com/jess2187/WastedPotential_Code"}]
        }
    ]

    let projects = projectsData.map(item => 
        <Project 
            name = {item.name}
            date = {item.date}
            description = {item.description}
            contribution = {item.contribution}
            tools = {item.tools}
            links = {item.links}
            image = {item.image}
            color = {item.color}
        />
    )
    
    return projects
}

export default Projects;
