import React, {useState, Button} from 'react'
import {Link} from 'react-router-dom'
import '../App.css'
import Resume from '../assets/Resume.pdf';
import { FiMenu, FiX } from "react-icons/fi";


function NavBar(props) {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  let items = [
    {name: "My Dog", link:"/dog"},
    {name: "Projects", link:"/projects"}, 
    {name:"About Me", link:"/about"},
  ]

  let tags = items.map((item, i) => {
    return (
      <li key={i} className="option" onClick={closeMobileMenu}>
        <Link className="normalLink" id={item.name} to={item.link}>{item.name}</Link>
      </li>
    )
  })

  return (
    <div className="navbar">
      <Link className="nameLink" style={{float:"left"}} to="/">Jessica Sanborn</Link>
      <ul className={click ? "nav-options active" : "nav-options"}>
        <li className="option" onClick={closeMobileMenu}><a className="normalLink" id="Contact" href="mailto:jessica.sanborn10@gmail.com">Contact Me!</a> </li>
        <li className="option" onClick={closeMobileMenu}><a className="normalLink" id="Resume" href={Resume} target="_blank">Resume</a> </li>
        {tags}
      </ul>
      <div className="mobile-menu" onClick={handleClick}>
        {click ? (
          <FiX className="menu-icon" />
        ) : (
          <FiMenu className="menu-icon" />
        )}
      </div>
    </div>
  );
}

export default NavBar;
