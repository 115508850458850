import React from 'react'
import '../App.css'

const About = (props) => {
  return(
    <div>
      <div className="section" style={{backgroundColor: '#f9faff'}}>
        <div className="sectionImage">
          <img src={'/baileyPhotos/bailey_14.jpeg'} alt={"bailey"} style={{width:"50%"}}/> 
        </div> 
        <div className="sectionInfo">
          <div id="text"> 
            <h2 style={{fontWeight:"bolder", marginTop:0}} >Interests in CS</h2>
            <p>
              I am passionate about full stack development, but especially frontend development and UX design.
              I love learning about psychology and UI/UX is a great combination of both of my interests.
            </p>
          </div>
        </div>
      </div>
      <div className="section" style={{backgroundColor: '#ffffff'}}>
        <div className="sectionInfo">
          <div id="text"> 
            <h2 style={{fontWeight:"bolder", marginTop:0}} >Cooking</h2>
            <p>
              One of my hobbies is cooking and baking. 
              I love getting creative in the kitchen and find it fun and a great break from code.
              When I was brainstorming for my current project I decided to create a recipe management app to combine two of my hobbies!
            </p>
          </div>
        </div>
      </div>
      <div className="section" style={{backgroundColor: '#f9faff'}}>
        <div className="sectionInfo">
          <div id="text"> 
            <h2 style={{fontWeight:"bolder", marginTop:0}} >Girls Who Code</h2>
            <p>
              I am very passionate about women in tech.&nbsp;
              <a style={{color: '#141452'}} href='https://girlswhocode.com/programs/clubs-program?gclid=Cj0KCQjwiYL3BRDVARIsAF9E4Gc2U7dqINQ_dkrdQfsDIGiHLnOpbHd4ChfDaQPPDySpqKooVXpxEJAaAlmiEALw_wcB' target="_blank">Girls Who Code</a> is a non-profit that aims to close the gender gap in tech.
              I participated in their Summer Immersion Program and it forever changed my life as I credit the program as to why I decided to study computer science and more importantly stick with it when things got tough.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About;
